import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'Parametros',
    loadChildren: () => import('./gr-parametros/gr-parametros.module').then(mod => mod.grParametrosModule)
  },
  {
    path: 'Gestion',
    loadChildren: () => import('./gr-gestion/gr-gestion.module').then(mod => mod.grGestionModule)
  },
  {
    path: 'Wfc',
    loadChildren: () => import('./gr-wfc/gr-wfc.module').then(mod => mod.grWfcModule)
  },
  {
    path: 'Consultar',
    loadChildren: () => import('./gr-consultar/gr-consultar.module').then(mod => mod.grConsultarModule)
  },
  {
    path: 'Reportes',
    loadChildren: () => import('./gr-reportes/gr-reportes.module').then(mod => mod.grReportesModule)
  },
  {
    path: 'Procesos',
    loadChildren: () => import('./gr-operaciones/gr-operaciones.module').then(mod => mod.grOperacionesModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CarteraRoutingModule { }
